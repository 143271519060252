.progress-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.progress-ring {
  position: relative;
  display: flex;
}

.progress-ring__circle {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.progress-ring__circle--grey {
  stroke: #ddd;
}

.progress-ring__circle--progress {
  stroke: #007bff;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s;
}

.count {
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}
