.home__wrapper {
  background: var(--white);
}

.banner {
  grid-column: span 2;
  background-color: var(--white);
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
}

.banner>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.banner>div>h1,
p {
  margin: 10px;
}

.banner>div>button {
  min-width: 250px;
  margin: 25px;
  padding: 0;
}

.service-container {
  margin: 20px;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
}

.service {
  max-height: 376px;
}

.service-container .service {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
}

.service>svg {
  width: 100%;
  max-width: 469px;
}

.service>div>p {
  margin-left: 0;
}

@media screen and (min-width: 768px) {
  .home-container {
    grid-template-columns: 1fr 1fr;
  }

  .banner {
    grid-template-columns: 1fr 1fr;
    padding: 20px 100px;
    gap: 200px;
    align-items: center;
  }

  .banner>div {
    margin: 50px;
  }

  .banner>div {
    padding: 0 50px 0 0;
  }

  .banner>svg {
    padding: 20px 0;
  }

  .service-container {
    margin: 20px 120px;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .service-container .service {
    flex-direction: row;
  }

  .service {
    margin: 20px auto;
  }

  h1 {
    font-size: 42px;
  }

  .service:nth-child(even) {
    flex-direction: row-reverse;
    padding-left: 0;
  }

  .service>div {
    padding: 0 100px;
  }

  .service:nth-child(even)>div {
    padding-left: 0;
    padding-right: 200px;
  }
}
