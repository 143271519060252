.proforma-modal {
  max-width: 90%;
  margin: 40px auto 0 auto;
  overflow-y: auto;
  max-height: 90vh;
}

@media screen and (min-width: 400px) {
  .proforma-modal {
    max-width: 70vw;
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  width: 500px;
  max-height: 90%;
  padding: 20px;
  background: white;
  border: 1px solid #dbe7ff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  overflow: auto;
  outline: none;
}

.modal.overflow {
  overflow: hidden;
}

.modal.modal-del {
  min-height: auto;
}

.modal-users {
  width: auto;
  min-width: 500px;
}

.modal.modal-del .btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

@media screen and (max-width: 400px) {
  .modal.modal-del .btn {
    flex-direction: column;
  }
}

@media screen and (min-width: 900px) {
  .modal.modal-del .btn {
    flex-direction: row;
    gap: 20px;
  }

}

#dashboard-root .people {
  margin: 20px auto;
}

.people>div>*+* {
  margin-top: 20px;
}

@media screen and (max-width: 500px) {
  #dashboard-root .people {
    padding: 10px;
  }

  .modal {
    padding: 40px 20px;
    right: 50%;
    left: initial;
    transform: translate(50%, -50%);
  }
}

.modal button:disabled {
  background: #b8c6e4;
}

@media screen and (min-width: 800px) {
  .modal__extra {
    width: 50%;
    max-width: 900px;
    padding: 0;
  }
}

.signupModal {
  width: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  padding: 20px 50px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: top center;
  border: 1px solid #dbe7ff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  overflow: scroll;
}

@media screen and (max-width: 1200px) {
  .signupModal {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .signupModal {
    width: 90%;
  }
}

.modal2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  width: 923px;
  max-height: 90%;
  padding: 20px;
  background: white;
  border: 1px solid #dbe7ff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  overflow: auto;
}
