.spaced__text {
  margin-top: 80px;
}

.spaced__text p + p {
  margin-top: 30px;
}

.restricted * + * {
  margin-top: 30px;
}
