.file input::file-selector-button {
  color: var(--grey2);
  background-color: var(--grey5);
  padding: 0 30px 0 30px;
  letter-spacing: 0.5px;
  line-height: 60px;
  font-weight: bolder;
  min-width: 200px;
  width: auto;
  height: 60px;
  border-radius: 10px;
  border: 1px solid var(--grey2);
}

.file input::file-selector-button:hover {
  cursor: pointer;
  background-color: var(--grey4);
}
.file input {
  height: auto;
  background-color: transparent;
  border: none;
  padding: 0;
}
.file > button {
  margin-top: 50px;
}
