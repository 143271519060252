@import url(sanitize.css);

:root {
  font-size: 16px;
  font-family: "DM Sans" !important;
  line-height: 1.5;
  --red: red;
  --orange: orange;
  --yellow: yellow;
  --green: green;
  --blue: blue;
  --indigo: indigo;
  --violet: violet;
  --primary: #0148de;
  --secondary: #00c8f0;
  --accent: #fe9900;
  --accent1: #fE99001A;
  --white: #f2f6ff;
  --primary1: #011747;
  --primary2: #002776;
  --primary3: #0148de;
  --primary4: #3777ff;
  --primary5: #6c98f3;
  --primary6: #82aaff;
  --primary7: #b5ccfe;
  --primary8: #dbe7ff;
  --primary9: #ebf1ffb3;
  --grey1: #3a3e47;
  --grey2: #7b8599;
  --grey3: #a4b0c9;
  --grey4: #b8c6e4;
  --grey5: #cbd2e2;
  --grey6: #ebf1ffb3;
  --grey7: #e8eeffb2;
  --grey8: #b5ccfe66;
  --grey9: #ebf1ffb2;
  --grey10: #0027761A;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f145;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #002776b6;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #002776;
}

:-ms-input-placeholder {
  font-family: "DM Sans";
}

::-webkit-input-placeholder {
  font-family: "DM Sans";
}

:-moz-placeholder {
  font-family: "DM Sans";
}

::-moz-placeholder {
  font-family: "DM Sans";
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input {
  font-family: "DM Sans";
}

textarea {
  font-family: "DM Sans";
}

body {
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

select {
  font-family: "DM Sans", sans-serif;
  color: #a4b0c9;
}

p,
h1 {
  margin-left: 0;
}

a {
  text-decoration: none;
  color: #011747;
}

.link {
  text-decoration: none;
  color: var(--primary3);
}

.link-policy {
  text-decoration: underline;
  color: inherit;
}

.container {
  max-width: 1128px;
  margin: 0 auto;
  width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


.dashboard-container {
  padding: 20px 80px 20px 40px;
  margin-top: 47px;
}

@media screen and (max-width: 400px) {
  .dashboard-container {
    padding: 10px;
  }
}

footer {
  position: "fixed";
  left: "0";
  bottom: "0";
}

.page-container {
  min-height: 100vh;
  display: block;
}

@media screen and (max-width: 820px) {
  .container {
    padding: 0 20px;
  }

  .dashboard-container {
    /* text-align: center; */
    padding: 20px;
  }
}

form .error {
  border: 2px solid #e53e3e;
}

form .errorText {
  color: #e53e3e;
  margin-bottom: 16px;
}

.dropdown {
  background: none;
  background-color: #f2f6ff;
  font-size: 18px;
  padding: 5px 5px 10px 10px;
  display: block;
  width: 100%;
  border: 1px solid #dbe7ff;
  border-radius: 5px;
  margin: 10px 0 10px;
}

.dropdownLarge {
  background: none;
  background-color: #f2f6ff;
  font-size: 18px;
  padding: 5px 5px 10px 10px;
  display: block;
  width: 150px;
  border: 1px solid #dbe7ff;
  border-radius: 5px;
  margin: 10px 0 10px;
  max-height: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.receipt-column {
  text-decoration: underline;
  color: red
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #6c7ac9;
  color: #6c7ac9;
}

.pagination__link {
  font-weight: bold;
  margin-right: 8px;
}

.pagination__link--active a {
  color: #fff;
  margin-right: 8px;
  background: #6c7ac9;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  margin-right: 8px;
  border: 1px solid rgb(198, 197, 202);
}

.no-spinner {
  -moz-appearance: textfield;
  appearance: textfield;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
  appearance: textfield;
  /* Other modern browsers */
}

.search-icon:hover {
  transform: scale(1.2);
  /* Increase the size on hover */
  cursor: pointer;
  /* Show a pointer cursor on hover */
}

.download:hover {
  transform: scale(1.2);
  /* Increase the size on hover */
  cursor: pointer;
  /* Show a pointer cursor on hover */
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* This will make the container cover the full viewport height */
}

.custom-toast {
  z-index: 9999;
}

.relative {
  position: relative;
}

@media all and (max-width:500px) {
  .pagination a {
    padding: 4px;
  }

  .pagination a.buttons {
    display: none;
  }
}
